import React from "react";
import { useRouter } from "next/router";
import { ThemeProvider } from "styled-components";

import { Flex, H1, Text, theme } from "components";

type Props = {
  statusCode: number;
};

const Error = (props: Props) => {
  const router = useRouter();
  const { statusCode } = props;
  const asPath: string = (router && router.asPath) || "unknown path";
  const unknownError = statusCode >= 500;
  const message = unknownError ? "Something went wrong 😥" : "Not found 🤔";
  return (
    <ThemeProvider theme={theme}>
      <Flex
        column
        alignItems="center"
        justifyContent="center"
        p="sm"
        style={{
          height: "100vh",
          textAlign: "center"
        }}
      >
        <H1>
          {statusCode}: {message}
        </H1>

        {!unknownError && (
          <>
            <Text as="p">
              We could not find{" "}
              <Text as="span" style={{ wordBreak: "break-word" }}>
                {asPath.length && asPath.length > 70 ? (
                  <em title={asPath}>{asPath.slice(0, 70)} ...</em>
                ) : (
                  <>{asPath}</>
                )}
              </Text>
            </Text>

            <Text as="p">If we should have, please let us know</Text>
          </>
        )}

        {unknownError && (
          <Text as="p">
            Try it again, if it doesn&apos;t work, let us know.
          </Text>
        )}
      </Flex>
    </ThemeProvider>
  );
};

Error.getInitialProps = async ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null;
  return { statusCode };
};

export default Error;
