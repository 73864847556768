import React from "react";
import { useRouter } from "next/router";
import App from "next/app";
import Head from "next/head";
import { IntlProvider } from "react-intl";
import { BaseStyles } from "@urbaninfrastructure/react-ui-kit";

import { AppReloader } from "../components/AppReloader";
import { GlobalDataError, GlobalDataLoading } from "../modules/Layout";
import NoSystems from "../modules/Layout/NoSystems";
import withApollo from "../src/hocs/withApollo";
import { UpdateAdministrator } from "../src/mutations";
import { GlobalDataQuery } from "../src/queries";
//import * as gtag from "../src/gtag";
import { getTheme } from "../src/utils/themes";
import { redirect } from "../src/utils";
import { getIntlFormats } from "../src/utils/getIntlFormats";
import Sentry from "../src/initSentry";
import { GLOBAL_ROUTES, VIEW_AS_QUERY_PARAM } from "../src/constants";
import ErrorPage from "./_error";
import { AdministratorContext } from "../src/context/administrator";
import { SystemContext } from "../src/context/system";
import {
  GlobalStateProvider,
  initialState as globalInitialState
} from "../src/context/globalState";
import { getQueryString } from "../src/utils/getQueryString";
import { CustomAppContext, DecodedJWT } from "../src/types";
import enMessages from "../locale/en.json";

import "../src/intl-polyfills";
import { ThemeProvider } from "@material-ui/core";
import muiTheme from "src/utils/muiTheme";

const locale = "en";
const messages = {
  en: enMessages
};

interface MyWindow extends Window {
  __NEXT_DATA__: any;
}

declare let window: MyWindow;

const Favicons = ({ colourKey }: { colourKey: string | null }) => {
  if (!colourKey) {
    return null;
  }

  const path = `https://assets.urbansharing.design/v1/favicons/admin/${colourKey}`;
  return (
    <Head>
      <link
        key="favicon-16"
        rel="icon"
        href={`${path}/favicon-16x16.png`}
        type="image/x-icon"
        sizes="16x16"
      />
      <link
        key="favicon-32"
        rel="icon"
        href={`${path}/favicon-32x32.png`}
        type="image/x-icon"
        sizes="32x32"
      />
      <link
        key={`apple-touch-icon`}
        rel="apple-touch-icon"
        href={`${path}/apple-touch-icon-180x180.png`}
        type="image/png"
        sizes="180x180"
      />
    </Head>
  );
};

const publicPaths = ["/logout", "/"];

type AppProps = {
  Component: React.ComponentType<any>;
  user: DecodedJWT | undefined;
  isViewAs: boolean;
  initialNow: number;
  statusCode?: number;
  pageProps: any;
};

function Page({
  Component,
  initialNow,
  user,
  statusCode,
  pageProps,
  isViewAs
}: AppProps) {
  const router = useRouter();
  const intlProviderProps = {
    locale,
    messages: messages[locale],
    initialNow
  };

  if (statusCode) {
    return <ErrorPage statusCode={statusCode} />;
  }

  pageProps = {
    ...pageProps,
    user,
    url: router
  };

  const { systemId, __notifyTimeout, __notifyCount } = router.query;
  const isGlobal = GLOBAL_ROUTES.includes(
    getQueryString(router.query.systemId)
  );

  const notifyTimeout = parseInt(getQueryString(__notifyTimeout)) || 300000;
  const notifyCountBeforeReload = parseInt(getQueryString(__notifyCount)) || 6;

  function shouldChangeSystem(selectedSystemId: string): boolean {
    return (
      !isGlobal &&
      !isViewAs &&
      Boolean(systemId) &&
      selectedSystemId !== systemId
    );
  }

  const baseStylesProps = {
    font: "urban-grotesk" as const
  };

  if (router.asPath === "/" || router.asPath.includes("?redirect")) {
    const theme = getTheme();
    return (
      <IntlProvider {...intlProviderProps}>
        <BaseStyles {...baseStylesProps} theme={theme}>
          <Component {...pageProps} />
          <Favicons colourKey={theme.name} />
        </BaseStyles>
      </IntlProvider>
    );
  }

  let initialUser = user ? { id: user.id.toString() } : globalInitialState.user;
  if (getQueryString(router.query[VIEW_AS_QUERY_PARAM])) {
    initialUser = {
      id: getQueryString(router.query[VIEW_AS_QUERY_PARAM])
    };
  }

  const initialState = {
    ...globalInitialState,
    user: initialUser
  };
  return (
    <ThemeProvider theme={muiTheme}>
      <GlobalStateProvider key={systemId as string} initialState={initialState}>
        <UpdateAdministrator
          onError={error => {
            const noAccessError =
              error &&
              error.graphQLErrors.some(
                graphQLError =>
                  // @ts-ignore
                  graphQLError.code === "ERR_ADMIN_USER_NOT_AUTHENTICATED"
              );
            if (noAccessError) {
              router.push("/");
            }
          }}
        >
          {(updateAdministrator, updateAdministratorProps) => {
            const { error } = updateAdministratorProps;
            const notFoundError =
              error &&
              error.graphQLErrors.some(
                // @ts-ignore
                graphQLError => graphQLError.code === "ERR_RECORD_NOT_FOUND"
              );

            if (notFoundError) {
              return <ErrorPage statusCode={404} />;
            }

            return (
              <GlobalDataQuery
                notifyOnNetworkStatusChange
                onCompleted={data => {
                  if (!data || !data.administrator) {
                    return;
                  }
                  const { administrator } = data;
                  const { selectedSystem } = administrator;
                  if (
                    !updateAdministratorProps.loading &&
                    shouldChangeSystem(selectedSystem.id)
                  ) {
                    updateAdministrator({
                      variables: {
                        id: administrator.id,
                        input: { selectedSystemId: systemId as string }
                      }
                    });
                  }

                  Sentry.configureScope(scope => {
                    scope.setUser({
                      id: administrator.id,
                      email: administrator.email || undefined,
                      username: administrator.name || undefined
                    });
                  });
                }}
              >
                {globalDataProps => {
                  const { data, loading, error } = globalDataProps;

                  const selectedSystem =
                    data &&
                    data.administrator &&
                    data.administrator.selectedSystem;

                  let isChangingSystem = false;
                  let colorKey;

                  if (selectedSystem) {
                    isChangingSystem = shouldChangeSystem(selectedSystem.id);
                    colorKey = selectedSystem.colourKey;
                  }
                  const formats = getIntlFormats(
                    selectedSystem ? selectedSystem.currency : ""
                  );

                  const theme = getTheme(colorKey);

                  let component;

                  if (error) {
                    if (
                      error.graphQLErrors &&
                      error.graphQLErrors.some(
                        // @ts-ignore
                        e => e.code === "ERR_ADMIN_USER_NO_SYSTEM_ACCESS"
                      )
                    ) {
                      component = <NoSystems />;
                    } else {
                      component = <GlobalDataError error={error} />;
                    }
                  } else if (
                    isChangingSystem ||
                    updateAdministratorProps.loading
                  ) {
                    component = (
                      <GlobalDataLoading>Changing system</GlobalDataLoading>
                    );
                  } else if (loading && (!data || !data.systems)) {
                    component = <GlobalDataLoading />;
                  } else {
                    component = (
                      <Component
                        globalData={{ ...data, loading, error }}
                        {...pageProps}
                      />
                    );
                  }

                  const timeZone = selectedSystem
                    ? selectedSystem.timezone
                    : undefined;

                  return (
                    <IntlProvider
                      {...intlProviderProps}
                      timeZone={timeZone}
                      formats={formats}
                    >
                      <SystemContext.Provider
                        // @ts-ignore
                        value={selectedSystem}
                      >
                        <AdministratorContext.Provider
                          // @ts-ignore
                          value={(data && data.administrator) || null}
                        >
                          <AppReloader
                            notifyTimeout={notifyTimeout}
                            notifyCountBeforeReload={notifyCountBeforeReload}
                          />

                          <Favicons colourKey={colorKey} />
                          <BaseStyles
                            {...baseStylesProps}
                            colorKey={colorKey}
                            theme={theme}
                          >
                            {component}
                          </BaseStyles>
                        </AdministratorContext.Provider>
                      </SystemContext.Provider>
                    </IntlProvider>
                  );
                }}
              </GlobalDataQuery>
            );
          }}
        </UpdateAdministrator>
      </GlobalStateProvider>
    </ThemeProvider>
  );
}

class AdminApp extends App<AppProps> {
  static async getInitialProps(appContext: CustomAppContext) {
    const { ctx } = appContext;
    const { query, asPath } = ctx;

    let statusCode: number | undefined;
    const initialNow = Date.now();

    let user;
    if (ctx.req) {
      const { getUserFromSSOCookie } = await import("../src/auth");
      user = getUserFromSSOCookie(ctx.req);
    } else {
      user = window.__NEXT_DATA__.props.user;
    }

    ctx.user = user;

    // if no user and trying to access other routes than publicPaths, redirect to root
    if (
      !user &&
      ctx.res &&
      asPath &&
      !publicPaths.includes(asPath) &&
      !asPath.includes("redirect")
    ) {
      redirect(`/?redirect=${asPath}`, ctx.res);
    }

    let isViewAs = false;

    if (query && query[VIEW_AS_QUERY_PARAM]) {
      isViewAs = true;
      user.id = query[VIEW_AS_QUERY_PARAM];
    }

    if (statusCode && ctx.res) {
      ctx.res.statusCode = statusCode;
    }

    const appProps = await App.getInitialProps(appContext);

    return {
      pageProps: appProps.pageProps,
      isViewAs,
      initialNow,
      user,
      statusCode
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);

    // This is needed to render errors correctly in development / production
    super.componentDidCatch(error, errorInfo);
  }

  render() {
    return <Page {...this.props} />;
  }
}

export default withApollo(AdminApp, { ssr: true, ssrAppTree: true });
