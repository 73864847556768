import { createTheme } from "@material-ui/core/styles";
import { theme } from "@urbaninfrastructure/react-ui-kit";

const muiTheme = createTheme({
  overrides: {
    MuiSelect: {
      root: {
        border: "1px solid #C1C2CF",
        fontSize: `${theme.fontSizes[0]}px`,
        padding: "0.5rem 1rem",
        backgroundColor: "#fff",
        "&&:focus": {
          backgroundColor: "#fff",
          borderRadius: "6px"
        }
      },
      select: {
        borderRadius: "6px",
        padding: "0.75rem 1rem",
        lineHeight: "1"
      }
    },
    MuiInput: {
      root: {
        "&&:before": {
          content: "none"
        },
        "&&:after": {
          content: "none"
        },
        minWidth: "200px"
      }
    },
    MuiMenuItem: {
      root: {
        paddingLeft: "12px"
      }
    },
    MuiSvgIcon: {
      root: {
        width: "1.25em"
      }
    },
    MuiOutlinedInput: {
      root: {
        width: "auto",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.colors.borderGray}`
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.colors.borderGray}`
        },
        "&:focus .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.colors.borderGray}`
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.colors.borderGray}`,
          borderWidth: "1px"
        }
      }
    }
  }
});

export default muiTheme;
