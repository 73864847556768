import merge from "lodash/merge";
import { DefaultTheme } from "styled-components";
import { getTheme as getUIKitTheme } from "@urbaninfrastructure/react-ui-kit";
import { ThemeKey } from "@urbaninfrastructure/design-tokens";

const defaultColorKey: ThemeKey = "purple01";

type FontSize = number;
type _FontSizes = Partial<{ body: FontSize }> & FontSize[];
type FontSizes = { body: FontSize } & FontSize[];

const typoStyles = {
  xxs: {
    fontSize: { xs: "12px", lg: "14px" },
    lineHeight: 1.5,
    fontWeight: null
  },
  xs: {
    fontSize: { xs: "14px", lg: "16px" },
    lineHeight: 1.5,
    fontWeight: null
  },
  sm: {
    fontSize: { xs: "18px", lg: "26px" },
    lineHeight: 1.5,
    fontWeight: null
  },
  md: {
    fontSize: { xs: "25px", lg: "40px" },
    lineHeight: 1.5,
    fontWeight: "bold"
  },
  lg: {
    fontSize: { xs: "30px", lg: "48px" },
    lineHeight: 1.15,
    fontWeight: "bold"
  },
  xl: {
    fontSize: { xs: "30px", lg: "40px" },
    lineHeight: 1.15,
    fontWeight: "bold"
  },
  xxl: {
    fontSize: { xs: "45px", lg: "85px" },
    lineHeight: 1.15,
    fontWeight: "bold"
  }
};

export function getTheme(colourKey: ThemeKey = defaultColorKey): DefaultTheme {
  const theme = getUIKitTheme(colourKey);

  const _fontSizes: _FontSizes = [12, 14, 16, 24, 30, 40, 60, 70];
  _fontSizes.body = _fontSizes[1];
  const fontSizes = _fontSizes as FontSizes;
  const textSize = fontSizes[2];

  const shadows = {
    ...theme.shadows,
    light: "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 7px rgba(0, 0, 0, 0.06);",
    heavy: "0px 0px 80px rgba(0, 0, 0, 0.08),0px 3px 10px rgba(0, 0, 0, 0.1);"
  };

  switch (colourKey) {
    case "bikeMi": {
      theme.colors.primaryIcon = theme.colors.primaryDark = theme.colors.primaryText = theme.colors.text =
        "black";
      break;
    }
    case "edinburghCityBike": {
      theme.colors.link = theme.colors.text;
      break;
    }
    default: {
      break;
    }
  }

  return merge({}, theme, {
    preset: "admin",
    fontSizes,
    textSize,
    maxWidth: "1600px",
    typoStyles,
    colors: {
      link: theme.colors.text
    },
    shadows,
    reactDatepicker: {
      daySize: [36, 40],
      fontFamily: theme.fonts.sansSerif,
      inputFontWeight: "normal",
      inputFontSize: theme.fontSizes[1],
      selectDateDateFontWeight: "normal",
      selectDateDateFontSize: theme.fontSizes[1],
      dateRangeGridTemplateColumns: { _: null, md: "300px 39px 300px" },
      dateRangeGridTemplateRows: { _: "1fr 36px 1fr", md: "unset" },
      datepickerSelectDateGridTemplateColumns: "1fr 36px 1fr",
      colors: {
        accessibility: theme.colors.primary,
        selectedDay: theme.colors.palette[colourKey],
        selectedDayHover: theme.colors.palette[colourKey],
        primaryColor: theme.colors.primary
      },
      inputLabelBorderRadius: "6px",
      datepickerBorderRadius: "md",
      dateRangeBorderRadius: "md",
      inputBackground: "transparent",
      datepickerCloseWrapperZIndex: 2
    }
  });
}
