import React from "react";
import { useIdleTimer } from "react-idle-timer";
import {
  addNotification,
  clearNotifications
} from "../src/context/globalState";
import { Button } from "components";
import { useEventTracking, useGlobalState } from "../src/hooks";
import { VERSION } from "../src/constants";

let notificationsCounter = 0;

export const AppReloader = ({
  notifyCountBeforeReload,
  notifyTimeout
}: {
  notifyCountBeforeReload: number;
  notifyTimeout: number;
}) => {
  const trackEvent = useEventTracking();
  const [, dispatchGlobalState] = useGlobalState();
  const { reset } = useIdleTimer({
    debounce: 250,
    timeout: notifyTimeout,
    onIdle: async () => {
      // check if new version have changed
      const manifest: { version: string } = await fetch(
        `/manifest.json?${new Date().getTime()}`
      ).then(res => res.json());

      notificationsCounter++;

      if (VERSION !== manifest.version) {
        const [currentMajor, currentMinor] = VERSION.split(".");
        const [manifestMajor, manifestMinor] = manifest.version.split(".");
        const newMajorVersion = currentMajor !== manifestMajor;
        const newMinorVersion = currentMinor !== manifestMinor;

        // if notified enough times, force reload if major or minor change
        if (
          (newMajorVersion || newMinorVersion) &&
          notificationsCounter > notifyCountBeforeReload
        ) {
          trackEvent({
            category: "New version notification",
            action: "Was force reloaded"
          });
          window.location.reload(true);
          return;
        }

        // show notification
        trackEvent({
          category: "New version notification",
          action: "Saw notification"
        });

        dispatchGlobalState(
          addNotification(
            `old-version-${manifest.version}`,
            "New version available",
            "info",
            <>
              <Button
                small
                variant="secondary"
                ml="xs"
                onClick={() => {
                  trackEvent({
                    category: "New version notification",
                    action: "Clicked reload"
                  });
                  dispatchGlobalState(clearNotifications());

                  window.location.reload(true);
                }}
              >
                Reload
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  trackEvent({
                    category: "New version notification",
                    action: "Clicked not now"
                  });
                  dispatchGlobalState(clearNotifications());
                  reset();
                }}
              >
                Not now
              </Button>
            </>,
            true,
            true
          )
        );
      }
    }
  });

  return null;
};
