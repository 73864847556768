import React from "react";
import { Flex, Box, Text } from "components";

const NoSystems = () => {
  return (
    <Flex justifyContent="center" alignItems="center" column height="100vh">
      <Box>
        <Text fontSize={5}>👋</Text>
        <Text as="p">You don&apos;t have access to any systems.</Text>
        <Text as="p">Please contact your system administrator.</Text>
      </Box>
    </Flex>
  );
};
export default NoSystems;
