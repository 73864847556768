import { CustomFormats } from "react-intl";

export function getIntlFormats(currency: string | null): CustomFormats {
  return {
    date: {
      "long-datetime": {
        // @ts-ignore not sure why hourCycke is missing in CustomFormats, but it's needed for not rendering 24:00 in some browsers
        hourCycle: "h23",
        minute: "numeric",
        hour: "numeric",
        year: "numeric",
        month: "long",
        day: "numeric"
      },
      "year-only": { year: "numeric" },
      date: {
        year: "numeric",
        month: "long",
        day: "numeric"
      }
    },
    number: {
      currency: {
        style: "currency",
        currency: currency || undefined
      }
    }
  };
}
