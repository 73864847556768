import { ApolloLink, FetchResult, Observable, Operation } from "apollo-link";
import { Client } from "graphql-ws";
import { print } from "graphql/language/printer";

/**
 * Apollo v3 has its own implementation of GraphQLWsLink
 * So this class is only needed while we're on Apollo v2
 * @see https://github.com/enisdenjo/graphql-ws#recipes
 */
export class GraphQLWsLink extends ApolloLink {
  constructor(private client: Client) {
    super();
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable(sink => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink)
        }
      );
    });
  }
}
