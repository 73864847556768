import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject
} from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { ErrorResponse, onError } from "apollo-link-error";
import { ApolloLink, Operation } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { print } from "graphql/language/printer";
import fetch from "isomorphic-fetch";
import {
  BASE_URL,
  CORE_ENDPOINT,
  GITSHA,
  SSO_ENDPOINT,
  VERSION
} from "../constants";
import { isServer, redirect } from "../utils";
import Sentry from "../initSentry";
import { Core$GraphQLError } from "../types";
// https://www.apollographql.com/docs/react/advanced/fragments.html
import introspectionQueryResultData from "./core-fragment-types.json";
import { NextPageContext } from "next";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "./GraphQLWsLink";

const uri = `${CORE_ENDPOINT}/api/v1/graphql`;
const graphiqlUri = `${CORE_ENDPOINT}/graphql/explore`;

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

type Options = {
  res?: NextPageContext["res"];
  viewAsAdminId: string | null;
  getToken: () => string | null;
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const getGraphiQLUrl = (operation: Operation): string => {
  const params = [];
  Object.keys(operation).forEach(key => {
    if (!operation || !operation[key]) {
      return;
    }
    const value =
      key === "query" ? print(operation[key]) : JSON.stringify(operation[key]);
    // @ts-ignore
    params.push(`${key}=${encodeURIComponent(value)}`);
  });
  return `${graphiqlUri}?${params.join("&")}`;
};

const reportErrors = onError(props => {
  const { graphQLErrors, networkError, operation } = props;
  if (graphQLErrors || networkError) {
    const graphiqlUrl = getGraphiQLUrl(operation);
    if (graphQLErrors) {
      // @ts-ignore
      graphQLErrors.map((error: Core$GraphQLError) => {
        const { code, message } = error;
        const errorMessage = `[GraphQL error]: ${code}: ${message}`;

        Sentry.configureScope(scope => {
          scope.setExtra("graphiqlUrl", graphiqlUrl);
          scope.setExtra("error", error);
          if (error.code && error.message) {
            scope.setFingerprint([
              `code: ${error.code}`,
              `message: ${error.message}`
            ]);
          }
        });

        Sentry.captureException(new Error(errorMessage));
      });
    }
    if (networkError) {
      Sentry.configureScope(scope => {
        if (graphiqlUrl) {
          scope.setExtra("graphiqlUrl", graphiqlUrl);
        }
      });
      Sentry.captureException(
        new Error(
          `[Network error]: ${
            typeof networkError === "string"
              ? networkError
              : JSON.stringify(networkError)
          }`
        )
      );
    }
  }
});

function hasErrorCode(code, { graphQLErrors, networkError }) {
  let hasErrorCode = false;
  if (graphQLErrors) {
    hasErrorCode = graphQLErrors.some(
      graphQLError => graphQLError.code === code
    );
  }
  if (!hasErrorCode && networkError && networkError.result) {
    hasErrorCode = networkError.result.code === code;
  }
  return hasErrorCode;
}

export function handleUnauthorized(
  { networkError, graphQLErrors }: ErrorResponse,
  res: NextPageContext["res"]
): void {
  // @ts-ignore
  const statusCode = networkError && networkError.statusCode;
  if (
    (networkError && (statusCode === 401 || statusCode === 403)) ||
    hasErrorCode("ERR_INVALID_TOKEN", {
      networkError,
      graphQLErrors
    })
  ) {
    // Don't redirect to login if trying to do mutations when viewAs
    if (
      hasErrorCode("ERR_FORBID_MUTATION_WHEN_VIEWAS", {
        networkError,
        graphQLErrors
      })
    ) {
      return;
    }
    redirect(`${SSO_ENDPOINT}/auth/logout?redirect=${BASE_URL}`, res);
  }
  return;
}

const unauthorizedLink = res =>
  onError(error => {
    handleUnauthorized(error, res);
  });

const authMiddleware = (getToken, viewAsAdminId) =>
  setContext(() => {
    const token = getToken();
    const headers: { [key: string]: string } = {
      authorization: token ? `Bearer ${token}` : ""
    };
    if (viewAsAdminId) {
      headers.viewas = viewAsAdminId;
    }
    return {
      headers
    };
  });

const timeStartLink = new ApolloLink((operation, forward) => {
  operation.setContext({ startTimingFrom: new Date().getTime() });
  return forward(operation);
});

const logTimeLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(data => {
    if (isServer) {
      return data;
    }
    return data;
  });
});

// https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-676886539
const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: any) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map(data => {
    return data;
  });
});

function create(initialState: NormalizedCacheObject, options: Options) {
  const { res, viewAsAdminId, getToken } = options;
  const httpLink = ApolloLink.from([
    timeStartLink,
    logTimeLink,
    new HttpLink({ uri, fetch })
  ]);

  let link = httpLink;

  if (!isServer) {
    const wsLink = new GraphQLWsLink(
      createClient({
        url: `${uri.replace("http", "ws")}/subscriptions`,
        connectionParams: {
          authToken: getToken()
        }
      })
    );

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    link = ApolloLink.split(
      // split based on operation type
      ({ query }) => {
        // @ts-ignore
        const { kind, operation } = getMainDefinition(query);
        return kind === "OperationDefinition" && operation === "subscription";
      },
      wsLink,
      httpLink
    );
  }

  return new ApolloClient({
    name: "admin",
    version: `${VERSION}:${GITSHA}`,
    ssrMode: typeof window === "undefined", // Disables forceFetch on the server (so queries are only run once)
    // ssrForceFetchDelay: 100, // this wreck ssr:false queries
    link: ApolloLink.from([
      cleanTypeName,
      reportErrors,
      unauthorizedLink(res),
      authMiddleware(getToken, viewAsAdminId),
      link
    ]),
    cache: new InMemoryCache({ fragmentMatcher }).restore(initialState || {}),
    connectToDevTools: !isServer, // even in production
    defaultOptions: {
      watchQuery: {
        errorPolicy: "all"
      },
      query: {
        errorPolicy: "all"
      }
    }
  });
}

export function initApollo(
  initialState: NormalizedCacheObject,
  options: Options
): ApolloClient<NormalizedCacheObject> {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === "undefined") {
    return create(initialState, options);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
}
