import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import Head from "next/head";
import cookie from "cookie";
import { initApollo, handleUnauthorized } from "../data/initApollo";
import Sentry from "../initSentry";
import { VIEW_AS_QUERY_PARAM, JWT_COOKIE_NAME } from "../constants";
import { globalData } from "../queries";
import { globalDataVariables } from "../core-types";
import { CustomNextReq, CustomAppContextType } from "../types";
import { getQueryString } from "../utils/getQueryString";

interface MyWindow extends Window {
  __NEXT_DATA__: any;
}

declare let window: MyWindow;

function getToken(req?: CustomNextReq) {
  if (typeof window !== "undefined") {
    return window.__NEXT_DATA__.props.jwtToken;
  }
  if (req) {
    const cookies = cookie.parse(req.headers.cookie || "");
    return cookies[JWT_COOKIE_NAME];
  }
}

export default function withApollo(
  AppComponent,
  { ssr = true, ssrAppTree }: { ssr?: boolean; ssrAppTree?: boolean } = {}
) {
  const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
    const client =
      apolloClient ||
      initApollo(apolloState, {
        getToken: () => pageProps.jwtToken,
        viewAsAdminId: pageProps.router.query[VIEW_AS_QUERY_PARAM]
      });
    return (
      <ApolloProvider client={client}>
        <AppComponent {...pageProps} />
      </ApolloProvider>
    );
  };

  if (process.env.NODE_ENV !== "production") {
    // Find correct display name
    const displayName =
      AppComponent.displayName || AppComponent.name || "Component";

    // Set correct display name for devtools
    WithApollo.displayName = `withApollo(${displayName})`;
  }

  if (ssr || AppComponent.getInitialProps) {
    WithApollo.getInitialProps = async (appCtx: CustomAppContextType) => {
      const { ctx } = appCtx;
      const { AppTree, query } = ctx;

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      ctx.apolloClient = initApollo(
        {},
        {
          res: ctx.res,
          getToken: () => getToken(ctx.req),
          viewAsAdminId: getQueryString(query[VIEW_AS_QUERY_PARAM])
        }
      );

      const pageProps = AppComponent.getInitialProps
        ? await AppComponent.getInitialProps(appCtx)
        : {};

      pageProps.jwtToken = getToken(ctx.req);

      // Only on the server
      if (typeof window === "undefined") {
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return {};
        }

        if (ssr && ctx.res) {
          if (pageProps.user) {
            const variables: globalDataVariables = {
              administratorId: `${pageProps.user.id}`
            };

            try {
              await ctx.apolloClient.query({ query: globalData, variables });
            } catch (err) {
              handleUnauthorized(err, ctx.res);
              if (ctx.res.finished) {
                return {};
              }
            }
          }

          if (ssrAppTree) {
            try {
              // Run all GraphQL queries
              const { getDataFromTree } = await import("@apollo/react-ssr");
              await getDataFromTree(
                <AppTree {...pageProps} apolloClient={ctx.apolloClient} />
              );
            } catch (error) {
              // Prevent Apollo Client GraphQL errors from crashing SSR.
              // Handle them in components via the data.error prop:
              // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
              Sentry.captureException(error);
            }
          }
        }

        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        Head.rewind();
      }

      // Extract query data from the Apollo store
      const apolloState = ctx.apolloClient.cache.extract();

      return {
        ...pageProps,
        apolloState
      };
    };
  }

  return WithApollo;
}
