import * as Sentry from "@sentry/browser";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

import getConfig from "next/config";

const isServer = typeof window === "undefined";

const {
  publicRuntimeConfig: {
    SENTRY_CLIENT_DSN,
    VERSION,
    APP_ENV,
    GITSHA,
    development
  }
} = getConfig();

const config = {
  dsn: SENTRY_CLIENT_DSN,
  release: GITSHA,
  environment: APP_ENV,
  debug: development,
  enabled: !development,
  tags: {
    version: VERSION,
    git_commit: GITSHA,
    isServer: isServer
  },
  integrations: development ? [] : [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: 0.5,
  // https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
  ignoreErrors: [
    "ERR_UNRESPONSIVE_CONTROLLER",
    "ERR_ADMIN_USER_NOT_AUTHENTICATED",
    "ERR_RECORD_NOT_FOUND",
    "ERR_FORBID_MUTATION_WHEN_VIEWAS",
    "ERR_VALIDATION_FAILED",
    "ERR_SEQUELIZE: invalid input syntax for type integer"
  ],
  blacklistUrls: [
    "localhost",
    "127.0.0.1",
    // Google reCAPTCHA
    /gstatic.com\/recaptcha/i,
    // Google translate
    /translate\.googleusercontent\.com/i,
    // Google Maps
    /maps\.googleapis\.com/i,
    // Intercom
    /js\.intercomcdn\.com/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
  ]
};

Sentry.init(config);

export default Sentry;
